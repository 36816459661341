import { Injectable } from '@angular/core';
import { Value } from '@app-types/Value';
import { DomSanitizer } from '@angular/platform-browser';
import { LessonFeedbackValue } from '@app-types/LessonFeedback';
import { Difficulty } from '@app-types/Difficulty';
import { AppConfiguration } from '../../core/services/configuration/app.configuration';
import { IValue } from '../../core/interrfaces/IValue';
import { TranslateService } from '@ngx-translate/core';

export interface IWorkshopModalData {
  title: string;
  imageUrl: string;
  mobileImageUrl: string;
  imageUrl2x: string;
  mobileImageUrl2x: string;
}

export interface Review {
  title: string;
  nickname: string;
  comment: string;
}

export interface TitleAndDescription {
  title: string;
  description: string;
}

export interface WarningVariantsForQuiz {
  title: string;
  description: string;
  logoName?: string;
}

@Injectable({
  providedIn: 'root',
})
export class DictionaryService {
  constructor(
    private sanitizer: DomSanitizer,
    private appConfiguration: AppConfiguration,
    private i18n: TranslateService
  ) {}

  /*properties*/
  heights: Value<string>[] = [
    {
      value: 'ft',
      label: 'Ft',
    },
    {
      value: 'cm',
      label: 'Cm',
    },
  ];

  weights: Value<string>[] = [
    {
      value: 'lbs',
      label: 'Lbs',
    },
    {
      value: 'kg',
      label: 'Kg',
    },
  ];

  genders: IValue<string>[] = [
    {
      value: 'm',
      label: 'Male',
    },
    {
      value: 'f',
      label: 'Female',
    },
    {
      value: 'u',
      label: 'Other',
    },
  ];

  levelFilters: Value<number>[] = [
    {
      label: 'Beginner',
      value: Difficulty.BEGINNER,
      img: this.sanitizer
        .bypassSecurityTrustHtml(`<svg width="24" height="24" class="st-stroke-dark-grey">
              <use xlink:href="#power"></use>
            </svg>`),
      activeImg: this.sanitizer
        .bypassSecurityTrustHtml(`<svg width="24" height="24" class="st-stroke-black">
              <use xlink:href="#power-active"></use>
            </svg>`),
    },
    {
      label: 'Intermediate',
      value: Difficulty.INTERMEDIATE,
      img: this.sanitizer
        .bypassSecurityTrustHtml(`<svg width="41" height="24" class="st-stroke-dark-grey">
              <use xlink:href="#double-power"></use>
            </svg>`),
      activeImg: this.sanitizer
        .bypassSecurityTrustHtml(`<svg width="41" height="24" class="st-stroke-black">
              <use xlink:href="#double-power-active"></use>
            </svg>`),
    },
    {
      label: 'Fluent',
      value: Difficulty.FLUENT,
      img: this.sanitizer
        .bypassSecurityTrustHtml(`<svg width="58" height="24" class="st-stroke-dark-grey">
              <use xlink:href="#triple-power"></use>
            </svg>`),
      activeImg: this.sanitizer
        .bypassSecurityTrustHtml(`<svg width="58" height="24" class="st-stroke-black">
              <use xlink:href="#triple-power-active"></use>
            </svg>`),
    },
    {
      label: 'Advanced',
      value: Difficulty.ADVANCED,
      img: this.sanitizer
        .bypassSecurityTrustHtml(`<svg width="75" height="24" class="st-stroke-dark-grey">
              <use xlink:href="#quadruple-power"></use>
            </svg>`),
      activeImg: this.sanitizer
        .bypassSecurityTrustHtml(`<svg width="75" height="24" class="st-stroke-black">
              <use xlink:href="#quadruple-power-active"></use>
            </svg>`),
    },
  ];

  allLessonFilter: Value<number | null> = {
    label: 'All',
    value: null,
  };

  durationFilters: Value<number>[] = [
    {
      label: 'Short',
      value: 15,
    },
    {
      label: 'Medium',
      value: 30,
    },
    {
      label: 'Long',
      value: 45,
    },
  ];

  feedbackCases: Value<LessonFeedbackValue>[] = [
    {
      value: 'great',
      label: 'Great',
    },
    {
      value: 'challenge',
      label: 'Challenging',
    },
    {
      value: 'hard',
      label: 'Too hard',
    },
    {
      value: 'easy',
      label: 'Too easy',
    },
  ];

  statisticKeys = ['day', 'week', 'month', 'year', 'all'];
  statisticLabels = ['Today', 'This Week', 'This Month', 'This Year', 'Total'];

  messageForSuccessActivateCertificate =
    'Your Gift Certificate was added to your account. Its balance will be used towards any subscriptions and/or special offers on STRETCHIT. Happy stretching! *You must use the certificate until [date]';
  messageForConfirmDeleteCard = 'Do you really want to delete this card?';

  messageForRestDay =
    'Rest is an important part of your training. On rest days muscle fibers repair and rebuild themselves. If you still want to move today, take a Daily Stretch class on the Home screen to feel more mobile and invigorated without causing additional stress on the body.';

  messageForChallengeEnding(name: string, id: number): string {
    return this.i18n
      .instant(
        `You’ve completed the [challenge_name]. Way to go! You are making strides on your ${
          id === 38 ? 'fitness' : 'stretching'
        } goals`
      )
      .toString()
      .replace('[challenge_name]', this.i18n.instant(name));
  }

  reviews: Review[] = [
    {
      title: 'Amazing',
      nickname: 'by thewhitefern',
      comment:
        'I’ve taken more pilates/yoga classes than I could ever count. I’ve been in gymnastics/cheer and countless other sports involving intense stretching and this app is by far the best stretching I’ve ever done. 10000/10 would recommend.',
    },
    {
      title: 'Love it',
      nickname: 'by ari',
      comment:
        'This app helps me stay on top of some pretty necessary self-care. All of the instructors are clear and precise and the classes are really geared to my needs. It seems like a lot, but it is totally worth the monthly subscription if you use it like I do. If you’re looking for a lifestyle change, this app is a good place to start.',
    },
    {
      title: 'Great app',
      nickname: 'by Peggi234323432',
      comment:
        'I was looking for an easy way to help my body stay flexible and relaxed and this app helped me a lot. I am so addicted to the classes. they are great, doable, and easy. You will see the change in your body and mood that will never let yourself to let go of this app.',
    },
    {
      title: 'Recommend',
      nickname: 'by meow033',
      comment:
        'Most certainly the best stretching application I’ve ever tried. Well-structured classes, amazing timely support (via Instagram, email) and always keeping you engaged.',
    },
    {
      title: 'Excellent',
      nickname: 'by Theresa McG',
      comment:
        'As a former ballet dancer and teacher, these classes have been excellent in getting every part of my body stretched. After a couple of serious injuries and five kids, my body has really tightened up, and the STRETCHIT app classes have been a godsend. Highly, highly recommend for every body.',
    },
  ];

  menReviews: Review[] = [
    {
      title: 'Feel good',
      nickname: 'by Kay $',
      comment:
        'After completing a 30 day bendy back challenge I can honestly say that I see some real improvements in my shoulder mobility and back flexibility. This app has given me hope that it’s not all downhill from here, I just needed to do the right exercises. If you are finding that you stuck, i highly recommend doing one of the 30 day challenges, track your progress and see if it works for you!',
    },
    {
      title: 'Wonderful',
      nickname: 'by Tom Mickleburgh',
      comment:
        'STRETCHIT - wonderful app, easy to use with varied skill levels for all. My mobility and flexibility are improving with this app. I’m really pleased I found it’s helping my body no end.',
    },
    {
      title: 'Recommend',
      nickname: 'by Dave the ferg',
      comment:
        'I had a crook back and wanted to strengthen it gently. Now I have done 2 days total of stretching and training to do the splits. Recommend highly.',
    },
    {
      title: 'Great',
      nickname: 'by Leon Hendren',
      comment:
        'Great programs. I like it’s no extra chatter, straight into the class, great camera angles.',
    },
  ];

  programReviews: Review[] = [
    {
      title: 'GREAT APP',
      nickname: '@lena.beana927',
      comment:
        "@stretchitapp thank you so much! These stretch classes have been nothing short of amazing. I can't believe how fast I progressed! I can't wait to increase my flexibility in other areas",
    },
    {
      title: 'Wow',
      nickname: '@Mamba12',
      comment:
        "Thankfully your program didn't just make me flexible but it also helped me build the strength those scarred muscles needed. I have a much better and stronger back (and my scoliosis hurts less)",
    },
    {
      title: 'Wow',
      nickname: '@x.christmansson',
      comment:
        '@stretchitapp who would have known stretching could be so fun and efficient! Thank you! Im almost down to a split soon too',
    },
  ];

  landingProgramReviews: Review[] = [
    {
      title: 'Wow',
      nickname: 'by Blfesq',
      comment:
        'I am about to turn 50 and severely overweight- started to eat right and this is my first real effort in working out as my flexibility and balance have been terrible and heavy workouts just discourage me since I am so out of shape. These are challenging but doable. Only a week in and I am already seeing a difference in my flexibility and endurance. Feeling encouraged!',
    },
    {
      title: 'I like it',
      nickname: 'by Bella.Lana',
      comment:
        'Obese 60 and raring to fix my out of balance body. Finished my first full body challenge and thoroughly enjoyed it',
    },
    {
      title: 'Awesome app',
      nickname: 'by ari',
      comment:
        'This app helps me stay on top of some pretty necessary self care. All of the instructors are clear and precise and the classes are really geared to my needs. It seems like a lot, but it is totally worth the monthly subscription if you use it like I do. If you’re looking for a lifestyle change, this app is a good place to start',
    },
    {
      title: 'Love this app',
      nickname: 'by Star_Six',
      comment:
        'I’m 62 so body pain is starting to come in and I want to keep up with my son for sports so I need to keep myself in check. This app seems perfect so far and I hope I start to see some great progress but I feel better already.',
    },
    {
      title: 'Recommended',
      nickname: 'by Peggi234323432',
      comment:
        'I was looking for easy way to help body stay flexible and relax and this app helped me a lot. I am so addicted to the classes. They are great, doable and easy. You will see the change in your body and mood that will never let yourself to let go of this app.',
    },
    {
      title: 'Great app',
      nickname: 'by k ras',
      comment:
        'Been using this program on & off for the past year and have seen significant improvement in my ankle & hip mobility. This has helped my performance in strength training & has allowed me to get lower in my squats & maintain stability in lunges. My goal in addition to this is trying to do middle splits which I am currently working on!',
    },
    {
      title: 'Love it',
      nickname: 'by ShowMeKitties',
      comment: 'Definitely worth the money, helps me stay fit',
    },
  ];

  SEOTitlesAndDescriptions: { [key: string]: TitleAndDescription } = {
    challenges: {
      title: 'Challenges and Programs - STRETCHIT | Registration Open',
      description:
        'Take your flexibility journey to the next level with STRETCHIT’s challenges and programs. Join now and push yourself with expert guidance and support. Start achieving your goals today.',
    },
    packages: {
      title: 'Find Your Class - STRETCHIT | Join Now',
      description:
        'Join now and find a range of classes for all levels and goals. Improve your flexibility, mobility, and overall well-being with expert guidance. Start your journey to a healthier you.',
    },
    home: {
      title: 'Flexibility Videos with STRETCHIT | Watch Now',
      description:
        'Improve your flexibility and mobility with STRETCHIT’s collection of videos. Watch and learn effective stretching techniques for a healthier body. Start your journey today!',
    },
    giftCertificate: {
      title: 'Gift Certificate - STRETCHIT | Get Yours Now',
      description:
        'Give the gift of health and wellness to athletes, yogis, or anyone looking to improve their overall well-being. Purchase your gift certificate today and start stretching towards a better you.',
    },
    default: {
      title: 'STRETCHIT',
      description:
        'Stretching and flexibility video classes with thousands of great exercises, flows, and easy to follow instructions. The best of yoga, pilates and dance stretching techniques to help you reach your flexibility, health, and fitness goals. World class flexibility instruction, beautiful videos with descriptive voice cues, 30-day training programs, your own StretchIt cat (wait, what?), progress tracking, and more!',
    },
  };

  workshopModalData: { [key: string]: IWorkshopModalData } = {
    quiz67: this.getWorkShopData('flexibility'),
    'quiz67.1': this.getWorkShopData('flexibility'),
    'quiz67.2': this.getWorkShopData('splits'),
    'quiz67.3': this.getWorkShopData('flexibility'),
    'quiz67.4': this.getWorkShopData('flexibility'),
    'quiz67.5': this.getWorkShopData('flexibility'),
    quiz79: this.getWorkShopData('flexibility'),
    quiz113: this.getWorkShopData('flexibility'),
    quiz118: this.getWorkShopData('splits'),
    'quiz118.1': this.getWorkShopData('splits'),
    'quiz118.2': this.getWorkShopData('splits'),
    quiz133: this.getWorkShopData('flexibility'),
    quiz134: this.getWorkShopData('flexibility'),
    quiz135: this.getWorkShopData('flexibility'),
    quiz136: this.getWorkShopData('flexibility'),
    'quiz133.1': this.getWorkShopData('flexibility'),
    'quiz134.1': this.getWorkShopData('flexibility'),
    'quiz135.1': this.getWorkShopData('flexibility'),
  };

  descriptionsForDurationPageQuiz: { [key: number]: WarningVariantsForQuiz } = {
    7: {
      title: 'Great start!',
      description: 'Restore and maintain healthy movement',
    },
    14: {
      title: 'Great choice!',
      description: 'Perform day to day tasks more efficiently',
    },
    21: {
      title: 'Form a habit!',
      description: 'Improve your motivation and commitment',
    },
    28: {
      title: 'Achieve a milestone!',
      description: 'Release stiffness, alleviate pain, and stay healthy',
    },
    35: {
      title: 'Way to go!',
      description: 'Enjoy improved mobility and flexibility',
    },
    42: {
      title: 'Celebrate achievements!',
      description: 'Measure your progress to increase motivation',
    },
    49: {
      title: 'Visible results!',
      description: 'Transform your body and calm your mind',
    },
    56: {
      title: 'Dream becomes a reality!',
      description: 'Hard work pays off',
    },
    63: {
      title: 'Challenging yet doable!',
      description: 'Build self-discipline for a more successful you',
    },
    70: {
      title: 'Consistency is the key!',
      description: 'Improved flexibility and performance',
    },
    77: {
      title: 'Achieve your goal!',
      description: 'Enjoy long-lasting benefits of stretching',
    },
    84: {
      title: 'Become a pro!',
      description: 'Transform your mobility and flexibility',
    },
    91: {
      title: 'Superstar!',
      description: 'Get the most out of your training',
    },
  };

  descriptionsForIntensityPageQuiz: { [key: number]: WarningVariantsForQuiz } = {
    1: {
      title: 'Can you do more?',
      description: 'To maximize results we recommend to stretch at least 2-3 times a week',
      logoName: '#meh',
    },
    2: {
      title: 'Keep going',
      description: 'Test  the waters and get your body acquainted with stretching',
      logoName: '#smile',
    },
    3: {
      title: 'Develop consistency',
      description: 'Get used to regular stretching and see how your body responds',
      logoName: '#smile',
    },
    4: {
      title: 'Accept a challenge',
      description:
        'You don’t have to go "all in" every time. Even a little movement is better than nothing',
      logoName: '#smile',
    },
    5: {
      title: 'Increase willpower',
      description: "You're on a right path to achieving results!",
      logoName: '#smile',
    },
    6: {
      title: 'Go you!',
      description: 'Perfect for all levels',
      logoName: '#smile',
    },
    7: {
      title: 'Attention',
      description: 'Take a break if you ever feel tired or sore',
      logoName: '#info-warn',
    },
  };

  private getWorkShopData(key: 'flexibility' | 'splits'): IWorkshopModalData {
    const workShopData: Record<'flexibility' | 'splits', IWorkshopModalData> = {
      flexibility: {
        title: `Flexibility workshop`,
        imageUrl: `${this.appConfiguration.assets}/img/upsell-workshop/upsell-workshop.webp`,
        imageUrl2x: `${this.appConfiguration.assets}/img/upsell-workshop/upsell-workshop@2x.webp`,
        mobileImageUrl: `${this.appConfiguration.assets}/img/upsell-workshop/upsell-workshop-mobile.webp`,
        mobileImageUrl2x: `${this.appConfiguration.assets}/img/upsell-workshop/upsell-workshop-mobile@2x.webp`,
      },
      splits: {
        title: 'Splits workshop',
        imageUrl: `${this.appConfiguration.assets}/img/upsell-workshop/upsell-workshop-splits-2.png`,
        imageUrl2x: `${this.appConfiguration.assets}/img/upsell-workshop/upsell-workshop-splits-2@2x.png`,
        mobileImageUrl: `${this.appConfiguration.assets}/img/upsell-workshop/upsell-workshop-splits-2-mobile.png`,
        mobileImageUrl2x: `${this.appConfiguration.assets}/img/upsell-workshop/upsell-workshop-splits-2-mobile@2x.png`,
      },
    };
    return workShopData[key];
  }
}
